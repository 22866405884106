import type { RootState } from 'redux-store';
import moment from 'moment-timezone';
import flatten from 'lodash-es/flatten';
import { createSelector, createStructuredSelector } from 'reselect';
import {
  parseNewGuestQuery,
  getSelectedTableNames,
  getSelectedTables,
  getSelectedTablesBlocked,
  getSelectedUpcomingTables,
  getSelectedNotAvailableTables,
  getTablesByRoom,
  isWaitlistCreation,
  createReservationPayload,
  createWaitlistReservationPayload,
  getTableReservation,
  createQueueReservationPayload,
  isSmsOrEmailDisabled,
  getSelectedTableCombination,
  getTablesTurnTime,
} from 'modules/ReservationFunnel/services';
import { getWaitlistPopupTime } from 'modules/Waitlist/services';
import { validateTableSelection } from './validations';
import { TURN_TIME_TYPE } from 'modules/ReservationFunnel/constants';
import { STATUSES } from 'modules/ReservationsList/constants';
import {
  selectCallingCode,
  selectCurrentVenueId,
  selectCurrentVenueLanguage,
  selectDefaultTurnTime,
  selectIsShortTimeSubscribed,
  selectManualPaymentEnabled,
  selectVenueDefaultPaymentExpireHours,
  selectVenueCommunication,
  selectVenueCommunicationEdit,
  selectVenueIsFreemium,
} from '../Partner/selectors';
import { selectReservationById } from '../ReservationsList/selectors';
import { selectFooterDate } from 'modules/Footer/selectors';
import { getCurrentDateAndTime } from 'utils/date-and-time';
import { DATE_FORMAT } from 'constants/time-and-date';
import type { CommunicationEnabled, CustomCharge } from './types';

export const selectReservationFunnel = ({ reservationFunnel }: RootState) => reservationFunnel;

export const selectPreviousStep = ({ reservationFunnel }: RootState) =>
  reservationFunnel.previousStep;

export const selectIsTemporaryGuest = ({ reservationFunnel }: RootState) =>
  reservationFunnel.isTemporaryGuest;

const selectCommunication = ({ reservationFunnel }: RootState) => reservationFunnel.communication;

export const selectManualPayment = ({ reservationFunnel }: RootState) =>
  reservationFunnel.isManualPayment;

export const selectFunnelPaymentExpireHours = ({ reservationFunnel }: RootState) =>
  reservationFunnel.paymentExpireHours;

export const selectGuest = ({ reservationFunnel }: RootState) => reservationFunnel.guest || {};

export const selectGuestId = ({ reservationFunnel }: RootState) => reservationFunnel.guest?.id;

export const selectGuestQuery = ({ reservationFunnel }: RootState) => reservationFunnel.guestQuery;

const selectTables = ({ reservationFunnel }: RootState) => reservationFunnel.tables;

const selectErrorMessage = ({ reservationFunnel }: RootState) => reservationFunnel.errorMessage;

const selectAssignableTables = ({ reservationFunnel }: RootState) =>
  reservationFunnel.assignableTables;

export const selectSelectedTableIds = ({ reservationFunnel }: RootState) =>
  reservationFunnel.selectedTableIds;

export const selectSelectedTableIdsPreEdit = ({ reservationFunnel }: RootState) =>
  reservationFunnel.selectedTableIdsPreEdit;

export const selectTimeSlot = ({ reservationFunnel }: RootState) => reservationFunnel.timeSlot;

export const selectIsEditingGuest = ({ reservationFunnel }: RootState) =>
  reservationFunnel.isEditingGuest;

export const selectFunnelReservationId = ({ reservationFunnel }: RootState) => reservationFunnel.id;

export const selectPartySize = ({ reservationFunnel }: RootState) => reservationFunnel.partySize;

export const selectIsWalkin = ({ reservationFunnel }: RootState) => reservationFunnel.isWalkin;

export const selectNotifyTimes = ({ reservationFunnel }: RootState) =>
  reservationFunnel.notifyTimes;

export const selectNotes = ({ reservationFunnel }: RootState) => reservationFunnel.notes || '';

export const selectTagIds = ({ reservationFunnel }: RootState) => reservationFunnel.tagIds || [];

export const selectDate = ({ reservationFunnel }: RootState) => reservationFunnel.date;

export const selectAvailabilitiesAndSlots = ({ reservationFunnel }: RootState) =>
  reservationFunnel.availabilityAndSlots;

export const selectReservationLoading = ({ reservationFunnel }: RootState) =>
  reservationFunnel.reservationLoading;

export const selectCurrentStep = ({ reservationFunnel }: RootState) => reservationFunnel.step;

export const selectReservationSuccess = ({ reservationFunnel }: RootState) =>
  reservationFunnel.reservationSuccess;

const selectTurnTime = ({ reservationFunnel }: RootState) => reservationFunnel.turnTime;

export const selectCalendarData = ({ reservationFunnel }: RootState) =>
  reservationFunnel.calendarData;

export const selectLastCalendarFetchParams = ({ reservationFunnel }: RootState) =>
  reservationFunnel.lastCalendarFetchParams;

export const selectIsEdit = ({ reservationFunnel }: RootState) => reservationFunnel.isEdit;

export const selectIsSlotsLoading = ({ reservationFunnel }: RootState) =>
  reservationFunnel.isSlotsLoading;

export const selectIsTablesLoading = ({ reservationFunnel }: RootState) =>
  reservationFunnel.isTablesLoading;

export const selectVenueId = ({ reservationFunnel }: RootState) => reservationFunnel.venueId;

export const selectAvailabilityId = ({ reservationFunnel }: RootState) =>
  reservationFunnel.availabilityId;

export const selectIsCalendarLoading = ({ reservationFunnel }: RootState) =>
  reservationFunnel.isCalendarLoading;

export const selectIsCustomTime = ({ reservationFunnel }: RootState) =>
  reservationFunnel.isCustomTime;

export const selectIsQueue = ({ reservationFunnel }: RootState) => reservationFunnel.isQueue;

export const selectFunnelWaitlistId = ({ reservationFunnel }: RootState) =>
  reservationFunnel.notifylistId;

export const selectIsEditingQueue = ({ reservationFunnel }: RootState) =>
  reservationFunnel.isEditingQueue;

export const selectTemplate = ({ reservationFunnel }: RootState) => reservationFunnel.template;

export const selectIsReservationWithGuest = ({ reservationFunnel }: RootState) =>
  reservationFunnel.isReservationWithGuest;

export const selectLockTables = ({ reservationFunnel }: RootState) =>
  reservationFunnel.lockTables || [];

const selectTablesCombinations = ({ reservationFunnel }: RootState) =>
  reservationFunnel.tablesCombinations || [];

// Returns either the selected combination or `undefined` if no combination is selected
export const selectActiveCombination = createSelector(
  selectTablesCombinations,
  selectSelectedTableIds,
  getSelectedTableCombination
);

export const selectChargingFees = createSelector(
  (state: RootState) => state.reservationFunnel,
  (reservationFunnel) => ({
    chargingFees: reservationFunnel.chargingFees,
    isChargingFeesLoading: reservationFunnel.isChargingFeesLoading,
  })
);

export const selectThirdPartyBooker = ({ reservationFunnel }: RootState) =>
  reservationFunnel.thirdPartyBooker;

export const selectThirdPartyBookerStep = ({ reservationFunnel }: RootState) =>
  reservationFunnel.thirdPartyBookerStep;

export const selectFunnelVenueId = createSelector(
  selectCurrentVenueId,
  selectVenueId,
  (currentVenueId, funnelVenueId) => funnelVenueId || currentVenueId
);

export const selectParsedGuest = createSelector(
  selectCallingCode,
  selectGuestQuery,
  (callingCode, guestQuery) => parseNewGuestQuery(guestQuery, callingCode)
);

export const selectTableNames = createSelector(
  selectTables,
  selectSelectedTableIds,
  (tables, selectedTableIds) => getSelectedTableNames(tables, selectedTableIds)
);

export const selectTablesByRoom = createSelector(
  selectTables,
  selectAssignableTables,
  (tables, assignableTables) => getTablesByRoom(tables, assignableTables)
);

export const selectIsTablesPresent = createSelector(selectTables, (tables) => !!tables.length);

const selectSelectedTables = createSelector(
  selectTables,
  selectSelectedTableIds,
  (tables, selectedTableIds) => getSelectedTables(tables, selectedTableIds)
);

export const selectSelectedNotAvailableTables = createSelector(
  selectSelectedTables,
  selectAssignableTables,
  (selectedTables, assignableTables) =>
    getSelectedNotAvailableTables(selectedTables, assignableTables)
);

export const selectSelectedUpcomingTables = createSelector(
  selectSelectedTables,
  selectAssignableTables,
  (selectedTables, assignableTables) => getSelectedUpcomingTables(selectedTables, assignableTables)
);

export const selectActiveAvailability = createSelector(
  selectAvailabilityId,
  selectAvailabilitiesAndSlots,
  (availabilityId, availabilitiesAndSlots) => {
    const selected = availabilitiesAndSlots.find(
      ({ availability }: any) => availability.id === availabilityId
    );
    return selected?.availability || null;
  }
);

const selectTurnTimeType = createSelector(
  selectActiveAvailability,
  selectIsShortTimeSubscribed,
  ({ reservationFunnel }: RootState) => reservationFunnel.isSqueeze,
  (availability, isShortTimeSubscribed, isSqueeze) => {
    if (isSqueeze) {
      return TURN_TIME_TYPE.SQUEEZE;
    }
    if (isShortTimeSubscribed && availability?.shortTurnTime && !availability?.regularTurnTime) {
      return TURN_TIME_TYPE.SHORT;
    }
    return TURN_TIME_TYPE.REGULAR;
  }
);

export const selectSelectedTablesTurnTime = createSelector(
  selectTables,
  selectSelectedTableIds,
  selectDefaultTurnTime,
  selectTurnTimeType,
  selectTablesCombinations,
  (tables, selectedTableIds, defaultTurnTime, turnTimeType, tablesCombinations) =>
    getTablesTurnTime(
      getSelectedTables(tables, selectedTableIds),
      getSelectedTableCombination(tablesCombinations, selectedTableIds),
      turnTimeType,
      defaultTurnTime
    )
);

export const selectTableTurnTime = createSelector(
  selectTurnTime,
  selectSelectedTablesTurnTime,
  (turnTime, selectedTablesTurnTime) => (!turnTime ? selectedTablesTurnTime : turnTime)
);

export const selectSelectedTags = createSelector(
  [(state: RootState) => state],
  ({ tags, reservationFunnel }) =>
    flatten(tags.reservationTags?.map((category) => category?.tags))?.filter(({ id }) =>
      reservationFunnel.tagIds?.includes(id)
    )
);

const selectBlockedTables = createSelector(
  selectTimeSlot,
  selectSelectedTables,
  (timeSlot, selectedTables) => getSelectedTablesBlocked(selectedTables, timeSlot)
);

export const selectWaitlistTimes = createSelector(
  selectNotifyTimes,
  selectDate,
  (notifyTimes, selectedDate) => {
    const startTime = getWaitlistPopupTime(notifyTimes?.startTime, selectedDate).format();
    const endTime = getWaitlistPopupTime(notifyTimes?.endTime, selectedDate)
      .add(!notifyTimes?.endTime ? 1 : 0, 'hours') // only add an hour if no endTime for waitlist provided
      .format();
    return {
      waitlistStartTime: startTime,
      waitlistEndTime: endTime,
    };
  }
);

export const selectIsWaitlist = createSelector(selectNotifyTimes, (notifyTimes) =>
  isWaitlistCreation(notifyTimes)
);

const selectFunnelReservation = createSelector(
  [(state: RootState) => state, selectFunnelReservationId],
  (state, reservationId) => selectReservationById(state, reservationId)
);

export const selectIsEmailOrPhoneDisabled = createSelector(
  selectParsedGuest,
  selectGuest,
  selectFunnelReservation,
  (parsedGuest, guest, reservation) => isSmsOrEmailDisabled({ parsedGuest, guest, reservation })
);

export const selectIsCommunicationConfirmationChecked = createSelector(
  [
    selectCommunication,
    selectVenueCommunication,
    selectVenueCommunicationEdit,
    selectIsEdit,
    selectIsEmailOrPhoneDisabled,
  ],
  (
    confirmation,
    venueCommunication,
    venueCommunicationEdit,
    isEdit,
    { isSmsDisabled, isEmailDisabled }
  ) => {
    // we set the communication to the default value of the current venue depending if it's new or edit reservation
    const selectedCommunication = isEdit ? venueCommunicationEdit : venueCommunication;

    return {
      isSmsChecked: (confirmation.sms ?? selectedCommunication.sms) && !isSmsDisabled,
      isEmailChecked: (confirmation.email ?? selectedCommunication.email) && !isEmailDisabled,
    };
  }
);

const selectIsCommunicationPaymentChecked = (paymentCommunication?: CommunicationEnabled) =>
  createSelector(
    [selectCommunication, selectIsEmailOrPhoneDisabled],
    (communication, { isSmsDisabled, isEmailDisabled }) => ({
      isSmsChecked:
        (communication.smsPayment ?? paymentCommunication?.isSmsEnabled ?? false) && !isSmsDisabled,
      isEmailChecked:
        (communication.emailPayment ?? paymentCommunication?.isEmailEnabled ?? false) &&
        !isEmailDisabled,
    })
  );

export const selectIsCommunicationChecked = (paymentCommunication?: CommunicationEnabled) =>
  createSelector(
    [
      selectIsCommunicationConfirmationChecked,
      selectIsCommunicationPaymentChecked(paymentCommunication),
      selectManualPayment,
    ],
    (confirmationCommunicationChecked, paymentCommunicationChecked, isManualPayment) => {
      if (isManualPayment) {
        return paymentCommunicationChecked;
      }
      return confirmationCommunicationChecked;
    }
  );

const selectSelectedUpcomingTablesTime = createSelector(
  selectAssignableTables,
  selectSelectedTableIds,
  (assignableTables, selectedTableIds) =>
    selectedTableIds.map((id: any) => getTableReservation(assignableTables.upcoming, id))
);

const tableValidationSelector = createStructuredSelector({
  mobile: (_, mobile) => mobile,
  selectedTables: selectSelectedTables,
  partySize: selectPartySize,
  selectedTableIds: selectSelectedTableIds,
  isWalkin: selectIsWalkin,
  blockedTables: selectBlockedTables,
  occupiedTables: selectSelectedNotAvailableTables,
  upcomingTables: selectSelectedUpcomingTables,
  isEdit: selectIsEdit,
  selectedTableIdsPreEdit: selectSelectedTableIdsPreEdit,
  upcomingTablesTime: selectSelectedUpcomingTablesTime,
  tableCombinations: selectTablesCombinations,
  isFreemium: selectVenueIsFreemium,
  isTablesPresent: selectIsTablesPresent,
});

export const selectTableValidation = createSelector(
  tableValidationSelector,
  validateTableSelection
);

export const selectReservationPayload = createSelector(
  ({ reservationFunnel }: RootState) => reservationFunnel,
  selectCallingCode,
  selectIsCommunicationConfirmationChecked,
  selectTurnTimeType,
  selectDefaultTurnTime,
  selectTableValidation,
  selectCurrentVenueLanguage,
  (
    funnelState,
    callingCode,
    { isSmsChecked },
    turnTimeType,
    defaultTurnTime,
    { validationErrorMessage: frontendErrorMessage },
    { language: currentVenueLanguage }
  ) =>
    createReservationPayload({
      reservationFunnel: funnelState,
      callingCode,
      isSmsChecked,
      turnTimeType,
      defaultTurnTime,
      frontendErrorMessage,
      currentVenueLanguage,
    })
);

export const selectWaitlistReservationPayload = createSelector(
  ({ reservationFunnel }: RootState) => reservationFunnel,
  selectFunnelVenueId,
  (funnelState, venueId) => createWaitlistReservationPayload(funnelState, venueId)
);

export const selectQueueReservationPayload = createSelector(
  ({ reservationFunnel }: RootState) => reservationFunnel,
  selectFunnelVenueId,
  selectCallingCode,
  (funnelState, venueId, callingCode) =>
    createQueueReservationPayload(funnelState, venueId, callingCode)
);

export const selectReservationType = createSelector(
  selectIsWalkin,
  selectIsWaitlist,
  selectManualPayment,
  (isWalkin, isWaitlist, isManualPayment) => {
    if (isWalkin) {
      return 'Walk-in';
    }
    if (isWaitlist) {
      return 'Waitlist';
    }
    if (isManualPayment) {
      return 'Awaiting payment reservation';
    }
    return 'Regular reservation';
  }
);

export const selectNotAvailableTableReservation = (tableId: any) =>
  createSelector(selectAssignableTables, (assignableTables) =>
    getTableReservation(assignableTables.notAvailable, tableId)
  );

export const selectUpcomingTableReservation = (tableId: any) =>
  createSelector(selectAssignableTables, (assignableTables) =>
    getTableReservation(assignableTables.upcoming, tableId)
  );

export const selectFunnelErrorMessage = (mobile: any) =>
  createSelector(
    selectErrorMessage,
    selectSelectedTableIds,
    (state: RootState) => selectTableValidation(state, mobile),
    (backendErrorMessage, selectedTableIds, { validationErrorMessage }) => {
      const frontendErrorMessage = selectedTableIds.length ? validationErrorMessage : '';
      return {
        frontendErrorMessage,
        backendErrorMessage,
        errorMessage: backendErrorMessage || frontendErrorMessage,
      };
    }
  );

export const selectIsEditManualPaymentRsv = ({ reservationFunnel }: RootState) =>
  reservationFunnel?.status?.toUpperCase() === STATUSES.AWAITING_PAYMENT ||
  reservationFunnel?.isManualPaymentReactivation;

export const selectShouldShowManualPayment = createSelector(
  selectIsTemporaryGuest,
  selectManualPaymentEnabled,
  selectVenueDefaultPaymentExpireHours,
  selectIsWaitlist,
  selectIsWalkin,
  (isTemporaryGuest, isManualPaymentEnabled, manualPaymentExpireHours, isWaitlist, isWalkin) =>
    isManualPaymentEnabled &&
    manualPaymentExpireHours &&
    !isWaitlist &&
    !isWalkin &&
    !isTemporaryGuest
);

export const selectManualPaymentCustomCharge = ({
  reservationFunnel,
}: RootState): CustomCharge | null => reservationFunnel.customCharge;

export const selectIsQueueOpen = ({ reservationFunnel }: RootState) =>
  reservationFunnel.isQueueOpen;

export const selectFunnelDate = createSelector(
  ({ reservationFunnel }: RootState) => reservationFunnel,
  selectFooterDate,
  (reservationFunnel, footerDate) => {
    if (!footerDate || moment(footerDate).isBefore(getCurrentDateAndTime(), 'day')) {
      return reservationFunnel.date;
    }
    return moment(footerDate).format(DATE_FORMAT);
  }
);
